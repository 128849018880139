import React, {Component} from 'react';
import {Carousel} from "react-bootstrap";
import CustomerLayout from "@/Layouts/CustomerLayout";
import offer__background from "../../../../assets/CustomerAsset/images/banner/offer__background.jpg";
import off_one from "../../../../assets/CustomerAsset/images/50__off.png";
import offer__background_two from "../../../../assets/CustomerAsset/images/banner/offer__background-2.jpg";
import off_two from "../../../../assets/CustomerAsset/images/50__off-2.png";

export default function HomeSlider () {

    const slides = [
        {
            backgroundImage: `${offer__background}`,
            image: `${off_one}`,
            title: 'Save Big on Corporate Client Now',
            link: '#',
        },
        {
            backgroundImage: `${offer__background_two}`,
            image: `${off_two}`,
            title: 'Unlock Exclusive Home User Offers Today',
            link: '#',
        },
    ];
    return (
        <>
            <div className="hero-banner-slide position-relative fix add__banner m-0">
                <div className="hero-banner-slider-wrapper">
                    <Carousel>
                        {slides.map((slide, i) => (
                            <Carousel.Item interval={4000} key={i}>
                                <div
                                    className="rts-hosting-banner hosting__offer__bg"
                                    style={{
                                        backgroundImage: `url(${slide.backgroundImage})`,
                                    }}
                                >
                                    <div className="container d-block">
                                        <div className="row justify-content-md-center justify-content-lg-start">
                                            <div className="col-lg-4">
                                                <div className="banner-area">
                                                    <div className="banner-area__content">
                                                        <img src={slide.image} alt="" />
                                                        <h5 className="banner-area__content__title">
                                                            {slide.title}
                                                        </h5>
                                                        <a href={slide.link} className="banner-area__content__link">
                                                            View Now
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>

        </>
    );
}


{/*<section className="rts-hero-four rts-hero__four mern__hosting"*/}
{/*         style={{*/}
{/*             backgroundImage: `url(${bannerImage})`,*/}
{/*         }}*/}

{/*>*/}

{/*    <div className="container">*/}
{/*        <div className="row g-5 justify-content-between align-items-center">*/}
{/*            <div className="col-lg-8 col-xl-6 col-md-10">*/}
{/*                <div className="rts-hero-four__content" >*/}
{/*                    <p className="offer">Up to <span className="off">78%</span> off MERN Hosting</p>*/}
{/*                    <h1 className="banner__title" >*/}
{/*                        MERN Stack Hosting*/}
{/*                    </h1>*/}
{/*                    <p className="description">Find hosting tailored for MERN Stack applications,*/}
{/*                        offering full support for MongoDB, Express.js, React, and Node.js.*/}
{/*                    </p>*/}
{/*                    <div className="feature" >*/}
{/*                        <ul className="feature__list">*/}
{/*                            <li className="feature__item">Node.js Supported</li>*/}
{/*                            <li className="feature__item"> MongoDB Supported</li>*/}
{/*                            <li className="feature__item"> Litespeed Web Server</li>*/}
{/*                            <li className="feature__item"> 99.99% Uptime</li>*/}
{/*                            <li className="feature__item"> 24/7/365 Day Support</li>*/}
{/*                        </ul>*/}
{/*                    </div>*/}
{/*                    <div className="banner-buttons" >*/}
{/*                        <a href="#"*/}
{/*                           className="rts-btn btn__long secondary__bg secondary__color">get started <i*/}
{/*                            className="fa-solid fa-chevron-right"></i></a>*/}
{/*                        <a href="#" className="rts-btn btn__long border__white white__color">contact*/}
{/*                            us <i className="fa-solid fa-chevron-right"></i></a>*/}
{/*                    </div>*/}
{/*                </div>*/}
{/*            </div>*/}

{/*        </div>*/}
{/*    </div>*/}
{/*</section>*/}
